import React, { useEffect } from 'react';
import './PrivacyPolicy.css'; // Import CSS file for styling
import DefaultLayout from '../../layout/DefaultLayout';

const PrivacyPolicy = () => {
    useEffect(() => {
        document.body.classList.add("privacy-view");

        return () => {
            document.body.classList.remove("privacy-view");
        };
    }, []);

  return (
    <DefaultLayout>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="privacy-policy" dir='rtl'>
        <h1>الخصوصية وبيان سرية البيانات</h1>
        <p>نقدر مخاوفكم واهتمامكم بشأن خصوصية بياناتكم على شبكة الإنترنت. لقد تم إعداد هذه السياسة لمساعدتكم في تفهم طبيعة البيانات التي نقوم بتجميعها منكم عند زيارتكم لتطبيقنا على شبكة الانترنت وكيفية تعاملنا مع هذه البيانات الشخصية.</p>
        
        <h2>التصفح</h2>
        <p>لم نقم بتصميم هذا تطبيق من أجل تجميع بياناتك الشخصية من جهاز الكمبيوتر الخاص بك أثناء تصفحك لهذا التطبيق، وإنما سيتم فقط استخدام البيانات المقدمة من قبلك بمعرفتك ومحض إرادتك حسبما تنص سياسة الخصوصية هذه.</p>

        <h2>التزامنا بحماية الخصوصية</h2>
        <p>يلتزم مركز تليفزيون العاصمة بحماية خصوصية المستخدمين الذين يستغلون الخدمات والمعلومات الرقمية وتوضح سياسة الخصوصية هذه كيفية تعامل مركز تليفزيون العاصمة مع بياناتك الشخصية ويشمل ذلك:</p>
        <ul>
            <li>المعلومات والبيانات التي نقوم بجمعها وسبب جمعها</li>
            <li>كيفية استخدام تلك المعلومات</li>
            <li>الخيارات التي نقدمها لك بخصوص معلوماتك الشخصية بما في ذلك كيفية الوصل للمعلومات وتحديثها</li>
        </ul>

        <h2>المعلومات والبيانات</h2>
        <p>نحن نقوم بجمع المعلومات والبيانات بهدف تقديم خدمات أفضل لكافة مستخدمينا وذلك لان معرفة تفاصيل عنك يساعدنا في تقديم المزيد مما تحتاجه- مزيد من العروض والمنتجات الحصرية ومزيد من المعلومات والمسابقات وخبرة أفضل للمستخدم فضلاً عن ذلك، تساعدنا تلك المعلومات والبيانات في معرفة أشياء أكثر تعقيداً مثل الدعايات التي ستجدها أكثر نفعاً أو الخدمات والمعلومات التي تجدها أكثر صلةً بك وبالتالي الحصول على تجربة أفضل وأكثر إفادةً من ملكياتنا الرقمية.</p>
        
        <h2>طرق جمع المعلومات</h2>
        <p>نحن نقوم بجمع المعلومات والبيانات بطريقتين:</p>
        <h3>المعلومات والبيانات التي تقدمها لنا</h3>
        <p>نقوم بجمع المعلومات والبيانات الشخصية من المستخدمين عندما تتوفر لنا من خلال استخدام نماذج واستطلاعات رأي عبر الإنترنت وفي كل مرة تقوم أنت بإرسال رسالة إلكترونية بتفاصيل عنك بالإضافة إلى ذلك، قد تتطلب بعض خدماتنا قيامك بالتسجيل من أجل الاستفادة من بعض الميزات وفي حالات أخرى قد ندعوك للاشتراك في بعض الخدمات مثل النشرات البريدية أو خدمات المعلومات عبر الرسائل القصيرة عندما تقوم بذلك سوف نطلب منك معلومات شخصية كاسمك وعنوان بريدك الإلكتروني أو رقم هاتفك.</p>
        
        <h3>المعلومات التي نحصل عليها عن طريق تمتعك بخدماتنا</h3>
        <p>نقوم أيضاً بتجميع معلومات إضافية تلقائياً حول زيارتك لممتلكاتنا الرقمية ونحن نستخدم الكوكيز (معلومات مخزنة على حاسوب المستخدم) لتحديد هويتك عندما تقوم بزيارة ممتلكاتنا الرقمية وذلك من أجل متابعة نمط تصفحك ولإنشاء ملف بيانات الديمغرافية والكوكيز هي معلومة ترسل من خلال خادم الويب إلى متصفح الويب وهي تمكّن الخادم من جمع المعلومات من المتصفح وتسمح لك معظم المتصفحات بتعطيل الكوكيز إذا كنت ترغب بمعرفة كيف يمكنك القيام بذلك، يرجى الاطلاع على قائمة المساعدة في المتصفح الخاص بك ولكن يرجى ملاحظة أن بعض الكوكيز مهمة لنا حتى نتمكن من تزويدك بالخدمات التي طلبتها وسوف يؤدي إيقاف الكوكيز إلى الحد من استخدامك لملكياتنا الرقمية.</p>

        <h2>كيف نستخدم بياناتك ومعلوماتك الشخصية</h2>
        <p>نقوم بمعالجة المعلومات الشخصية المجمعة منك ومن ممتلكاتنا الرقمية لأغراض تزويدك بالخدمات التي تطلبها منا، بالإضافة إلى المحافظة على وتحسين الخدمات التي نزودك بها وتطوير خدمات جديدة كما ونستخدم المعلومات لإجراء أبحاث السوق ولنتمكن من تزويدك بمحتوى مصمم ليلبي ذوقك واحتياجاتك- كالدعايات والخدمات التي تتفق مع اهتماماتك.</p>
        
        <p>عندما تقوم بالاتصال بمجموعة (Alamein Fest) ابلكيشن مهرجان العالمين، قد نحتفظ بتسجيل اتصالك لمساعدتنا في حل أمور تواجهك وقد نستخدم بيانات الاتصال بك من أجل إرسال معلومات تسويقية مباشرة بما في ذلك إبلاغك عن خدماتنا مثل إعلامك عن البرنامج القادم أو التحسينات التي ستحظى بها ممتلكاتنا الرقمية.</p>
        
        <p>كما ونستخدم المعلومات التي نجمعها من الكوكيز لغرض تحسين تجربتك كمستخدم وضمان جودة خدماتك بشكل عام وقد نقوم بضم معلوماتك الشخصية التي حصلنا عليها من خلال إحدى الخدمات مع المعلومات التي جمعناها، بما في ذلك المعلومات الشخصية، من خدمات أخرى مقدمة من سيبي سي، مثلاً لتسهيل مشاركة الأشياء مع الناس الذين تعرفهم وسنطلب منك الموافقة قبل استخدام المعلومات لغرض غير الأغراض المنصوص عليها في سياسة الخصوصية هذه.</p>

        <h2>المعلومات التي نشاركها</h2>
        <p>سوف نقوم فقط بالإفصاح عن المعلومات الشخصية لأعضاء مجموعة (Alamein Fest) ابلكيشن مهرجان العالمين، وخلفائنا في العمل والموردين الذين يعملون معنا من أجل معالجة البيانات بالنيابة عنا وخلاف ذلك، لن نقوم أبداً بمشاركة أية معلومات شخصية نكون قد جمعناها منك مع أية شركات أخرى أو مؤسسات أو أفراد إلا إذا:</p>
        <ul>
            <li>قمنا بذلك بموجب موافقتك سوف نقوم بمشاركة معلوماتك الشخصية مع الشركات والمنظمات والأفراد خارج (Alamein Fest) ابلكيشن مهرجان العالمين عندما نحصل على موافقتك على قيامنا بذلك، بما في ذلك المعالجة الخارجية بحيث نزود شركاتنا الفرعية وغيرها من الشركات أو الأشخاص الموثوقين بمعلوماتك الشخصية من أجل معالجتها لنا بناء على تعليماتنا ووفقاً لسياسة الخصوصية الخاصة بنا وغيرها من تدابير السرية والأمان.</li>
            <li>أسباب قانونية سوف نقوم بمشاركة معلوماتك الشخصية مع الشركات والمنظمات والأفراد خارج مجموعة (Alamein Fest) ابلكيشن مهرجان العالمين في حال كنا نعتقد وبحسن نية بأن الوصول إلى واستخدام وحفظ والإفصاح عن المعلومات ضروري من أجل:
            <ul>
                <li>الإيفاء بمتطلبات أي قانون، أو لائحة أو إجراء قانوني أو طلب حكومي مطبق.</li>
                <li>تطبيق شروط الاستخدام المطبقة بما في ذلك التحقيق في مخالفات محتملة.</li>
                <li>الكشف عن ومنع أو التعامل مع محاولات التزوير أو المشاكل الأمنية أو التقنية.</li>
                <li>حماية الحقوق والممتلكات والأمن لمجموعة (Alamein Fest) ابلكيشن مهرجان العالمين، ولمستخدمينا أو للجمهور حسبما يتطلب أو يسمح به القانون.</li>
            </ul>
            </li>
        </ul>
        <p>قد نقوم بمشاركة معلومات مجمعة لا يمكن من خلالها تحديد الشخصية مع العامة ومع شركائنا– كالمعلنين أو المواقع المتصلة ويمكننا على سبيل المثال تشارك المعلومات علناً من أجل إظهار الميول الخاصة بالاستخدام العام لخدماتنا.</p>

        <h2>أمن المعلومات</h2>
        <p>نحن نبذل أقصى جهودنا لحماية مجموعة (Alamein Fest) ابلكيشن مهرجان العالمين ومستخدمينا من أي محاولة غير مصرح بها للوصول، أو الاستخدام أو التبديل أو الإفصاح أو الإتلاف للمعلومات التي نحتفظ بها وعلى الأخص:</p>
        <ul>
            <li>نقوم بمراجعة المعلومات التي جمعناها وإجراءات تخزينها ومعالجتها بما في ذلك تدابير الأمن المادي، من وقت لآخر، وذلك بهدف الحماية ضد الوصول غير المصرح به للأنظمة.</li>
            <li>نحن نحدّ إمكانية الوصول إلى المعلومات الخاصة بحيث يتم ذلك فقط من قبل موظفي مجموعة المخولين والمتعاقدين والوكلاء الذين هم بحاجة لمعرفة تلك المعلومات من أجل معالجتها لنا، ويخضع هؤلاء لالتزامات تعاقدية صارمة لحفظ السرية وقد يتم تأديبهم أو إنهاء عملهم في حال أخفقوا في تنفيذ هذه الالتزامات.</li>
        </ul>
        </div>
    </DefaultLayout>
  );
}

export default PrivacyPolicy;
