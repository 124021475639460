import axios from "axios";
import { API_URL } from "../_env";

export const subscribe = (email:string) => {
    return axios.post(
        `${API_URL}/api/subscribe?email=${email}`, {},
        {
            headers: {
                "API-KEY": "@$%tN5sWBkc4F9z3QW3bbYxL!a7U9VFAS*v3sfdF"
            }
        }
    );        
}

export const translate = (lang:string, arabic_text:string, english_text:string) => {
    return lang == 'en' ? english_text : arabic_text
}